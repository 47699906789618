import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { withRouter } from 'react-router';
import queryString from 'query-string';
import '../design/StudyMenu.css';
import { v4 as uuidv4 } from 'uuid';
import { initializeBloop } from "../utils/globalAudioEffect";
import { config } from './Constants';
import { getCards, putSetProgress, getSetProgress } from '../serverCalls/api2';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';

import { AuthenticationModal } from './subComponents/AuthenticationModal';
import CenterSpinner from "./subComponents/CenterSpinner";

const CardState = {
    front: "Front",
    back: "Back",
    complete: "Complete",
    pending: "Pending"
}

const parseTimeUnits = (ms) => {
    const minutes = 1000 * 60;
    const hours = minutes * 60;
    const days = hours * 24;

    if (ms > days) {
        return `${Math.ceil(ms/days)} days`
    } else if (ms > hours) {
        return  `${Math.ceil(ms/hours)} hours`
    } else {
        return `${Math.ceil(ms/minutes)} minutes`
    }
}

class StudyMenu extends React.Component {

    constructor({
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...props
    }) {
        super(props)

        this.superProps = props

        this.outerClasses = classNames(
            'features-tiles section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        this.innerClasses = classNames(
            'features-tiles-inner section-inner pt-0',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        this.tilesClasses = classNames(
            'tiles-wrap center-content',
            pushLeft && 'push-left'
        );

        this.sectionHeader = {
            title: 'Create a Study Set',
        };

        this.values = queryString.parse(this.props.location.search);

        this.toStudy = [];      // right -> correct; wrong -> toReview;
        this.correct = [];      // ...
        this.review = [];       // right -> incorrect; wrong -> toReview;
        this.incorrect = [];    // ...

        this.state = {
            question: "",
            answer: "",
            cardState: undefined
        }

        this.answeredCards = 0;

    }

    componentDidMount() {

        let currentComponent = this
        Auth.currentUserInfo().then(user => {
            currentComponent.user = user
            if (user) {
                // Authenticated
                getSetProgress(this.values.id).then(response => {
                    console.log(response)
                    this.toStudy = response.cards.map(item => {
                        return ({
                            question: item.question,
                            answer: item.answer,
                            cardId: item.cardId
                        })
                    })
                    if (this.toStudy.length > 0) {
                        this.getNextCard()
                    } else {
                        window.gtag('event', 'finish_set');
                        this.setState({
                            cardState: CardState.complete,
                            question: "",
                            answer: "",
                            msUntilDue: response.msUntilDue
                        })
                    }
                })
            } else {
                // Unauthenticated
                getCards(this.values.id).then(cards => {
                    this.toStudy = cards.data.Items.map(item => {
                        return ({
                            question: item.question.S,
                            answer: item.answer.S,
                            cardId: item.cardId.S
                        })
                    });
                    if (this.toStudy.length > 0) {
                        this.getNextCard()
                    } else {
                        this.setState({
                            cardState: CardState.complete,
                            question: "",
                            answer: ""
                        })
                    }
                })
            }
        })


    }

    componentWillUnmount() {
        window.gtag('event', 'answered_cards', {
            'value': this.answeredCards
        })
    }

    getNextCard = () => {

        if (this.toStudy.length > 0) {
            this.setState({
                cardState: CardState.front,
                question: this.toStudy[0].question,
                answer: this.toStudy[0].answer,
                checkCorrect: false
            })
        } else if (this.review.length > 0) {
            this.setState({
                cardState: CardState.front,
                question: this.review[0].question,
                answer: this.review[0].answer,
                checkCorrect: false
            })
        } else {
            this.setState({
                cardState: CardState.pending,
                question: "",
                answer: "",
                checkCorrect: false
            })
            if (this.user) {
                // Authenticated
                putSetProgress(this.values.id, this.correct.map((item) => ({cardId: item.cardId})), this.incorrect.map((item) => ({cardId: item.cardId}))).then(() => {
                    getSetProgress(this.values.id).then(response => {
                        this.toStudy = response.cards.map(item => {
                            return ({
                                question: item.question,
                                answer: item.answer,
                                cardId: item.cardId
                            })
                        })
                        if (this.toStudy.length > 0) {
                            this.getNextCard()
                        } else {
                            this.setState({
                                cardState: CardState.complete,
                                question: "",
                                answer: "",
                                msUntilDue: response.msUntilDue,
                                checkCorrect: false
                            })
                        }
                    })
                })
            } else {
                // Unauthenticated
                this.setState({
                    newUserSaveProgress: "signUp",
                    checkCorrect: false
                })
            }
        }

    }

    CardDisplay = () => {
        if (this.state.cardState === CardState.front || this.state.cardState === CardState.back) {
            return (
                <>
                    <div className="CardDisplay">
                        <div>
                            {(this.state.cardState===CardState.front) ? this.state.question : this.state.answer}
                        </div>
                    </div>
                    <button className="FlipButton" onClick={() => {
                        this.setState({
                            cardState: (this.state.cardState===CardState.front) ? CardState.back : CardState.front,
                            checkCorrect: true
                        })
                    }}>
                        Flip
                    </button>
                    {
                        (this.state.checkCorrect) ?
                            <>
                                <div className="AnswerPrompt">
                                    Were you correct?
                                </div>
                                <div className="AnswerButtonRow">
                                    <button className="AnswerButton" onClick={() => {
                                        this.answeredCards += 1
                                        if (this.toStudy.length > 0) {
                                            this.correct.push(this.toStudy.shift())
                                            this.getNextCard()
                                        } else {
                                            this.incorrect.push(this.review.shift())
                                            this.getNextCard()
                                        }
                                    }}>
                                        Yes
                                    </button>
                                    <button className="AnswerButton" onClick={() => {
                                        this.answeredCards += 1
                                        if (this.toStudy.length > 0) {
                                            this.review.push(this.toStudy.shift())
                                            this.getNextCard()
                                        } else {
                                            this.review.push(this.review.shift())
                                            this.getNextCard()
                                        }
                                    }}>
                                        No
                                    </button>
                                </div>
                            </>
                            :
                            <></>
                    }
                </>
            )
        } else if (this.state.cardState === CardState.complete) {
            return (
                <>
                    <div>
                        Complete
                    </div>
                    <div>
                        Your next question is due in {parseTimeUnits(this.state.msUntilDue)}.
                    </div>
                </>
            )
        } else if (this.state.cardState === CardState.pending) {
            return (
                <>
                    <CenterSpinner />
                </>
            )
        } else {
            return (
                <>
                    <CenterSpinner />
                </>
            )
        }
    }

    render() {
        return (
            <>

                <AuthenticationModal
                    modalState={this.state.newUserSaveProgress}
                    updateModalState={(s) => this.setState({ newUserSaveProgress: s })}
                    onAuthComplete={() => {
                        putSetProgress(this.values.id, this.correct, this.incorrect).then(() => {
                            window.location.reload()
                        })
                    }}
                    onAuthFail={() => this.props.history.push('/getstarted')}
                >
                    <>
                        <div style={{ "margin-bottom": "20px" }}>
                            MyVoiceCards uses spaced repetition to help you learn efficiently.
                            To continue studying and save your progress, sign up below.
                        </div>
                        <a href="https://en.wikipedia.org/wiki/Spaced_repetition">
                            (Click here to learn more about spaced repetition)
                        </a>
                    </>
                </AuthenticationModal>

                <section
                    {...this.props}
                    className={this.outerClasses}
                >
                    <div className="container">
                        <div className={this.innerClasses}>
                            <this.CardDisplay />
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default StudyMenu;