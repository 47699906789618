import React, { useEffect, useState } from 'react';
import '../../design/AuthenticationModal.css';
import {
  Button,
  Form,
  Modal,
  Spinner
} from 'react-bootstrap';

import { Auth } from 'aws-amplify';

export const AuthenticationModal = ({
  modalState,
  updateModalState,
  onAuthComplete,
  onAuthFail,
  ...props
}) => {

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [pending, setPending] = useState(false);

  return (
    <>
      <Modal
        show={modalState === "confirmPasswordReset"}
        centered="true"
        onHide={onAuthFail}
      >
        <Modal.Header>
          <Modal.Title>
            Confirm Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="SignUpForm">
            <Form.Group className="SignUpFormGroup" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control value={username} onChange={e => setUsername(e.target.value)} type="username" placeholder="Enter username" />
            </Form.Group>
            <Form.Group className="SignUpFormGroup" controlId="formBasicUsername">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control value={code} onChange={e => setCode(e.target.value)} placeholder="Enter confirmation code" />
            </Form.Group>
            <Form.Group className="SignUpFormGroup" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control onKeyPress={(e) => {
                if (e.charCode == 13) {
                  setPending(true)
                  Auth.signIn(username, password).then(() => {
                    setPending(false)
                    onAuthComplete()
                  }).catch((error) => {
                    setPending(false)
                    alert(`Error signing in: ${error.message}`)
                  })
                }
              }} value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Enter password" />
            </Form.Group>
            <Button className="SignUpFormButton" onClick={() => {
              setPending(true)
              Auth.forgotPasswordSubmit(username, code, password).then(() => {
                Auth.signIn(username, password).then(() => {
                  setPending(false)
                  onAuthComplete()
                }).catch(() => {
                  setPending(false)
                  updateModalState("signIn");
                })
              }).catch((error) => {
                setPending(false);
                alert(`Error resetting password: ${error.message}`);
              })
            }}>
              {
                pending ?
                  <Spinner animation="border" size="sm" />
                  :
                  "Reset Password"
              }
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalState === "passwordReset"}
        centered="true"
        onHide={onAuthFail}
      >
        <Modal.Header>
          <Modal.Title>
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="SignUpForm">
            <Form.Group className="SignUpFormGroup" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control onKeyPress={(e) => {
                if (e.charCode == 13) {
                  setPending(true)
                  Auth.signIn(username, password).then(() => {
                    setPending(false)
                    onAuthComplete()
                  }).catch((error) => {
                    setPending(false)
                    alert(`Error signing in: ${error.message}`)
                  })
                }
              }} value={username} onChange={e => setUsername(e.target.value)} type="username" placeholder="Enter username" />
            </Form.Group>
            <Button className="SignUpFormButton" onClick={() => {
              setPending(true)
              Auth.forgotPassword(username).then(() => {
                setPending(false);
                updateModalState('confirmPasswordReset')
              }).catch((error) => {
                setPending(false);
                alert(`Error resetting password: ${error.message}`);
              })
            }}>
              {
                pending ?
                  <Spinner animation="border" size="sm" />
                  :
                  "Reset Password"
              }
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalState === "signIn"}
        centered="true"
        onHide={onAuthFail}
      >
        <Modal.Header>
          <Modal.Title>
            Sign In
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="SignUpForm">
            <Form.Group className="SignUpFormGroup" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control value={username} onChange={e => setUsername(e.target.value)} type="username" placeholder="Enter username" />
            </Form.Group>
            <Form.Group className="SignUpFormGroup" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control onKeyPress={(e) => {
                if (e.charCode == 13) {
                  setPending(true)
                  Auth.signIn(username, password).then(() => {
                    setPending(false)
                    onAuthComplete()
                  }).catch((error) => {
                    setPending(false)
                    alert(`Error signing in: ${error.message}`)
                  })
                }
              }} value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Enter password" />
            </Form.Group>
            <Button className="SignUpFormButton" onClick={() => {
              setPending(true)
              Auth.signIn(username, password).then(() => {
                setPending(false)
                onAuthComplete()
              }).catch((error) => {
                setPending(false)
                alert(`Error signing in: ${error.message}`)
              })
            }}>
              {
                pending ?
                  <Spinner animation="border" size="sm" />
                  :
                  "Sign In"
              }
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer className="SignUpForm">
          <div>
            <span style={{ color: '#61cbf2', cursor: 'pointer' }} onClick={() => updateModalState("passwordReset")}>Forgot Password?</span>
          </div>
          <div>
            Don't have an account? <span style={{ color: '#61cbf2', cursor: 'pointer' }} onClick={() => updateModalState("signUp")}>Sign Up</span>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalState === "signUp"}
        centered="true"
        onHide={onAuthFail}
      >
        <Modal.Header>
          <Modal.Title>
            Sign Up
          </Modal.Title>
          {props.children}
        </Modal.Header>
        <Modal.Body>
          <Form className="SignUpForm">
            <Form.Group className="SignUpFormGroup" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control value={username} onChange={e => setUsername(e.target.value)} type="username" placeholder="Enter username" />
            </Form.Group>
            <Form.Group className="SignUpFormGroup" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Enter password" />
            </Form.Group>
            <Form.Group className="SignUpFormGroup" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control onKeyPress={(e) => {
                if (e.charCode == 13) {
                  setPending(true)
                  Auth.signUp({
                    username,
                    password,
                    attributes: {
                      email
                    }
                  }).then(() => {
                    window.gtag('event', 'create_account');
                    Auth.signIn(username, password).then(() => {
                      setPending(false)
                      onAuthComplete()
                    }).catch(() => {
                      setPending(false)
                      updateModalState("signIn");
                    })
                  }).catch((error) => {
                    setPending(false)
                    alert(`Error signing up: ${error.message}`)
                  })
                  console.log({ username, password, email })
                }
              }} value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter email" />
            </Form.Group>
            <Button className="SignUpFormButton" onClick={() => {
              setPending(true)
              Auth.signUp({
                username,
                password,
                attributes: {
                  email
                }
              }).then(({ user }) => {
                window.gtag('event', 'create_account');
                setPending(false)
                updateModalState("confirmation")
              }).catch((error) => {
                setPending(false)
                alert(`Error signing up: ${error.message}`)
              })
            }}>
              {
                pending ?
                  <Spinner animation="border" size="sm" />
                  :
                  "Sign Up"
              }
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer className="SignUpForm">
          <div>
            Already have an account? <span style={{ color: '#61cbf2', cursor: 'pointer' }} onClick={() => updateModalState("signIn")}>Sign In</span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )

}