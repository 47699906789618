import React from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import '../design/Login.css';
import '../design/EditHome.css';
import '../design/SearchHome.css';
import '../design/Set.css';
import ColoredLine from "./subComponents/ColoredLine";
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import MobileStoreButton from 'react-mobile-store-button';

import { Auth } from 'aws-amplify';

import { withRouter } from 'react-router';

import { getCards, getSetHeader, linkMaker } from '../serverCalls/api2';
import { ModalDialog } from "react-bootstrap";

const emailMatchRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class EditHome extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			cards: [],
			title: this.props.title,
			owner: "",
			edit: false,
			shareModal: false,
			teacher: 0,
			unsharedClasses: [],
			mailingListEmail: "",
			shareModal: false,
			studyMethodModal: true,
			mobileVoiceModal: false,
		}

		this.userName = "NONE";

		this.values = queryString.parse(this.props.location.search);
		this.changed = false;

	}

	componentDidMount() {

		// Display the pitch screen but only ever once
		const show_pitch = ['yes', 'no'][Math.floor(Math.random() * 2)];

		if (this.values.fromSync) {
			localStorage.setItem('pitchShown', 'yes');
		} else {
			var pitchShown = localStorage.getItem('pitchShown') || '';
			console.log(pitchShown)
			if (pitchShown != 'yes') {

				console.log(`show_pitch: ${show_pitch}`)
				window.gtag('event',`show_pitch_${show_pitch}`)

				// A/B Testing
				if (show_pitch === 'yes') {
					this.props.history.push(`pitch?id=${this.values.id}`);
				} else {
					// Do nothing
					this.branchLinkPromise = linkMaker(this.values.id,['from_set'])
				}
				localStorage.setItem('pitchShown', 'yes');
			}
		}

		this.loadCards();
	}

	setSession = (newSession) => {
		this.session = newSession
	}

	deleteCard = (index) => {
		let newCards = this.state.cards;
		newCards.splice(index, 1);
		this.setState({
			cards: newCards,
		})
	}

	createBlankCard = (index) => {
		let newCards = this.state.cards;
		let blankCard = {
			"answer": " ",
			"wrongAnswer1": " ",
			"wrongAnswer2": " ",
			"wrongAnswer3": " ",
			"rank": " ",
			"deckId": this.values.id,
			"cardId": index,
			"type": "multipleChoice",
			"prompt": " ",
		};
		newCards.splice(index, 0, blankCard);
		this.setState({
			cards: newCards,
		})
	}

	setEdit = (edit) => {
		this.setState({
			edit: edit,
		})
	}

	setTitle = (newTitle) => {
		this.setState({
			title: newTitle,
		})
	}

	setOwner = (newOwner) => {
		this.setState({
			owner: newOwner,
		})
	}

	setShareModal = (isVisible) => {
		this.setState({
			shareModal: isVisible,
		})
	}

	setStudyModal = (isVisible) => {
		this.setState({
			studyModal: isVisible,
		})
	}

	setTeacher = (isTeacher) => {
		this.setState({
			teacher: isTeacher,
		})
	}

	setUnsharedClasses = (classes) => {
		this.setState({
			unsharedClasses: classes,
		})
	}

	componentWillUnmount = () => {
		if (this.changed) {
			if (window.confirm("You have unsaved changes. Press OK to save them.")) {
				this.saveChanges()
			} else {
				// Do nothing
			}
		}
	}

	addCard = () => {
		var newCards = this.state.cards;
		let newCardIndex = newCards.length
		newCards.push({
			deckId: this.props.location.state.deckId,
			cardId: newCardIndex,
			prompt: " ",
			rank: " ",
			type: "multipleChoice",
			answer: " ",
			wrongAnswer1: " ",
			wrongAnswer2: " ",
			wrongAnswer3: " "
		});
		this.setState({ cards: newCards });
	}

	startStudying = () => {
		throw "Please implement the study instructions"
	}

	loadCards = async () => {
		let currentComponent = this;
		getCards(this.values.id).then(response => {
			if (response.success) {
				currentComponent.setState({
					cardsFetched: true,
					cards: response.data.Items.map(item => {
						return ({
							question: item.question.S,
							answer: item.answer.S
						})
					})
				})
			} else {
				// TODO: Resolve the error
				console.error(JSON.stringify(response))
			}
		})
		getSetHeader(this.values.id).then(response => {
			if (response.success) {
				currentComponent.setState({
					title: response.data.Item.title.S,
					owner: response.data.Item.username.S
				})
			}
		})
		Auth.currentUserInfo().then(user => {
			if (user) {
				this.setState({
					username: user.username
				})
			}
		})
	}

	TitleBar = (props) => {

		return (
			<div>
				{this.state.title}
				<ColoredLine color="white" />
				{this.state.owner}
			</div>
		)
	}

	copyTextToClipoard = (text) => {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		textArea.style.position = "fixed";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
		} catch (err) {
			console.error(`Copying failed: ${err}`)
		}
	}

	ActionBar = (props) => {
		return (
			<div className="SetMenuActionBar">
				<Button className="ActionsElement" onClick={() => this.setShareModal(true)}>Share</Button>
				<Button className="ActionsElement" onClick={() => this.setState({ studyMethodModal: true })}>Study</Button>
				{
					(this.state.username == this.state.owner && this.state.username != null) ?
						<Button className="ActionsElement" onClick={() => {
							window.gtag('event', 'edit_set');
							this.props.history.push(`/edit?id=${this.values.id}`)
						}}>Edit</Button>
						:
						<></>
				}
			</div>
		)
	}

	Question = (props) => {
		return (
			<div className="Question">
				<div className="QuestionHeader">
					{props.data.question}
				</div>
				<div className="FlashCardAnswer">
					{props.data.answer}
				</div>
			</div>
		)
	}

	render() {

		const outerClasses = classNames(
			'features-tiles section',
			this.props.topOuterDivider && 'has-top-divider',
			this.props.bottomOuterDivider && 'has-bottom-divider',
			this.props.hasBgColor && 'has-bg-color',
			this.props.invertColor && 'invert-color',
			this.props.className
		);

		const innerClasses = classNames(
			'features-tiles-inner section-inner pt-0',
			this.props.topDivider && 'has-top-divider',
			this.props.bottomDivider && 'has-bottom-divider'
		);

		return (
			<div className="HomeBackground">

				<Modal
					show={this.state.shareModal}
					centered="true"
					scrollable="true"
					onHide={() => this.setShareModal(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title>Share</Modal.Title>
					</Modal.Header>
					<div className="ShareableLinkBlock">
						<Modal.Title>Shareable Link</Modal.Title>
						<div className="ShareableLinkBar">
							<div>{`https://myvoicecards.com/set?id=${this.values.id}`}</div>
							<div className="ShareSpace"></div>
							<Button
								className="ActionsElement"
								onClick={() => {
									window.gtag('event', 'share', { 'event_category': 'Set', 'event_value': this.values.id })
									this.copyTextToClipoard(`https://myvoicecards.com/set?id=${this.values.id}`);
								}}
							>
								Copy
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					show={this.state.studyMethodModal}
					centered="true"
					scrollable="true"
					onHide={() => this.setState({ studyMethodModal: false })}
				>
					<Modal.Header closeButton>
						<Modal.Title>How would you like to study?</Modal.Title>
					</Modal.Header>
					<div className="StudyMethodBlock">
						<Button
							className="SpeakButton"
							onClick={() => {
								window.gtag('event', 'study_speak');
								if (isMobile) {
									this.branchLinkPromise.then(({ url }) => {
										window.gtag('event', 'enter_branch');
										window.location.href = url
									})
								} else {
									this.props.history.push(`gomobile?id=${this.values.id}&from=from_set`)
								}
							}}
						>
							By Speaking
						</Button>
						<Button
							className="StudyMethodButton"
							onClick={() => {
								window.gtag('event', 'study_listen');
								if (isMobile) {
									this.branchLinkPromise.then(({ url }) => {
										window.gtag('event', 'enter_branch');
										window.location.href = url
									})
								} else {
									this.props.history.push(`gomobile?id=${this.values.id}&from=from_set`)
								}
							}}
						>
							By Listening
						</Button>
						<Button
							className="StudyMethodButton"
							onClick={() => {
								window.gtag('event', 'study_click');
								if (isMobile) {
									this.branchLinkPromise.then(({ url }) => {
										window.gtag('event', 'enter_branch');
										window.location.href = url
									})
								} else {
									this.props.history.push(`gomobile?id=${this.values.id}&from=from_set`)
								}
							}}
						>
							By Clicking
						</Button>
					</div>
				</Modal>

				<section
					{...this.props}
					className={outerClasses}
				>
					<div className="container">
						<div className={innerClasses}>
							<div style={{
								fontWeight: "bold",
								fontSize: "xx-large",
								marginLeft: "1.5%"
							}}>
								{this.state.title}
							</div>
							<this.ActionBar />
							{
								// Render cards
								(this.state.cardsFetched === true) ?
									(
										this.state.cards.map((thisCard, index) =>
											<this.Question data={thisCard} onChange={(e) => this.setSingleCard(index, e)}
												edit={this.state.edit} index={index} />
										)
									)
									:
									(
										<div className="SpinnerColumn">
											<div className="SpinnerRow">
												<Spinner animation="border" />
											</div>
										</div>
									)
							}
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(EditHome);