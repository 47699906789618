import React from 'react';
import { withRouter, useHistory } from 'react-router';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import '../design/Home.css';
import '../design/Anki.css';
import { getAnkiUpdate } from '../serverCalls/api2'

import { ReactComponent as AnkiSync } from '../design/AnkiSync.svg'

import {
    Button
} from 'react-bootstrap'

const Med = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Select a Study Set',
    };

    const history = useHistory()

    const ankiLinkPromise = getAnkiUpdate();

    // // Display the pitch screen but only ever once
    // var pitchShown = localStorage.getItem('pitchShown') || '';
    // console.log(pitchShown)
    // if (pitchShown != 'yes') {
    //     history.push(`pitch?anki=true`);
    //     localStorage.setItem('pitchShown', 'yes');
    // }

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>

                    <div className="HomeBackground">
                        <h1 style={{ textAlign: 'center' }} className="mt-16 mb-16">
                            MyVoiceCards & Anki
                        </h1>
                        <div className="container">
                            <div class="row justify-content-md-center">
                                <div class="col col-lg-6">
                                    <p style={{ textAlign: 'justify' }} className="mt-16 mb-16">
                                        Study your <a style={{color: 'blue'}} href="https://apps.ankiweb.net">Anki</a> decks on-the-go using voice commands. 
                                        Using the MyVoiceCards Add-On for Anki,
                                        you can pick any of your anki decks to sync with
                                        the MyVoiceCards app, and study your Anki
                                        flashcards anywhere.
                                    </p>
                                    <br />
                                    <div className="Centered">
                                        <Button onClick={() => {
                                            window.gtag('event', 'get_started_med')
                                            ankiLinkPromise.then(response => {
                                                console.log(response)
                                                window.gtag('event', 'get_anki_addon')
                                                window.open(response.url, '_blank').focus();
                                            })
                                        }} tag="a" style={{ backgroundColor: "#61cbf2", border: "solid 1px #61cbf2" }} wideMobile>
                                            Download the Add-On
                                        </Button>
                                    </div>
                                    <br />
                                    <AnkiSync />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default withRouter(Med);
