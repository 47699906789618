/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6b8a903d-ce39-452d-91cb-5384ce16db60",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_atg4Ikh59",
    "aws_user_pools_web_client_id": "1mi73qnjbqrsa26jimbn2m8a1g",
    "oauth": {}
};


export default awsmobile;
