import { Auth } from 'aws-amplify';

const authApiTemplate = (method, payload) => {
	return new Promise(resolve => {

		Auth.currentSession().then(res => {
			let idToken = res.getIdToken().getJwtToken()

			// Setting the timeout limit
			let timeoutSecondLimit = 10;
			var timedOut = false;
			const timeoutId = setTimeout(() => {
				timedOut = true;
				resolve({ success: false, message: "TimeOut" })
			}, timeoutSecondLimit * 1000);

			console.log(JSON.stringify(JSON.stringify(payload)))

			fetch(
				`https://8vxsmt5bpe.execute-api.us-east-2.amazonaws.com/v2/${method}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': idToken
					},
					body: JSON.stringify(payload)
				}
			).then(response =>
				response.json()
			).then(response => {
				clearTimeout(timeoutId);
				if (timedOut) return;
				resolve({ success: true, ...response });
			}).catch(err => {
				clearTimeout(timeoutId);
				if (timedOut) return;
				resolve({ success: false, ...err });
			})

		}).catch(err => {
			resolve({
				success: false,
				...err
			})
		})
	})
}

const apiTemplate = (method, payload) => {
	return new Promise(resolve => {

		// Setting the timeout limit
		let timeoutSecondLimit = 10;
		var timedOut = false;
		const timeoutId = setTimeout(() => {
			timedOut = true;
			resolve({ success: false, message: "TimeOut" })
		}, timeoutSecondLimit * 1000);

		console.log(JSON.stringify(JSON.stringify(payload)))

		fetch(
			`https://8vxsmt5bpe.execute-api.us-east-2.amazonaws.com/v2/${method}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			}
		).then(response =>
			response.json()
		).then(response => {
			clearTimeout(timeoutId);
			if (timedOut) return;
			resolve({ success: true, ...response });
		}).catch(err => {
			clearTimeout(timeoutId);
			if (timedOut) return;
			resolve({ success: false, ...err });
		})
	})
}

export const getCards = (setId) => {
	return apiTemplate('getcards', {
		setId
	})
}

export const getSetHeader = (setId) => {
	return apiTemplate('getsetheader', {
		setId
	})
}

export const getHomeData = () => {
	return authApiTemplate('gethomedata', {})
}

export const createSet = (title, cards, questionLang, answerLang) => {
	return authApiTemplate('createset', {
		title,
		cards,
		questionLang,
		answerLang
	})
}

export const putSetProgress = (setId, correct, incorrect) => {
	return authApiTemplate('putsetprogress', {
		setId,
		correct,
		incorrect
	})
}

export const getSetProgress = (setId) => {
	return authApiTemplate('getsetprogress', {
		setId
	})
}

export const startEdit = (setId) => {
	return authApiTemplate('startedit', {
		setId
	})
}

export const querySetTitles = (query) => {
	return apiTemplate('querysettitles', {
		query
	})
}

export const endEdit = (setId, newCards, editedCards, deletedCards, newTitle, questionLang, answerLang) => {
	return authApiTemplate('endedit', {
		setId,
		newCards,
		editedCards,
		deletedCards,
		newTitle,
		questionLang,
		answerLang
	})
}

export const getCardsPaged = (setId, nextPage) => {
	console.log(nextPage)
	return apiTemplate('getcardspaged', {
		setId,
		nextPage
	})
}

export const getCardsPagedAuth = (setId, nextPage) => {
	return authApiTemplate('getcardspagedauth', {
		setId,
		nextPage
	})
}

export const linkMaker = (set_id, tags) => {
	return apiTemplate('linkmaker', {
		set_id,
		tags
	})
}

export const plusLinkMaker = (subject, tags) => {
	return apiTemplate('linkmaker', {
		subject,
		tags
	})
}

export const getAnkiUpdate = () => {
	return new Promise(resolve => {
		fetch(
			`https://8vxsmt5bpe.execute-api.us-east-2.amazonaws.com/v2/getankiupdate`,
			{
				method: 'GET'
			}
		).then(response =>
			response.json()
		).then(response => {
			resolve({ success: true, ...response });
		}).catch(err => {
			resolve({ success: false, ...err });
		})
	})
}