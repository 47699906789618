import React from 'react';
import { withRouter, useHistory } from 'react-router';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import '../design/Home.css';
import '../design/Med.css';
import { plusLinkMaker } from '../serverCalls/api2'

import {
    Button
} from 'react-bootstrap'

const Med = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Select a Study Set',
    };

    const history = useHistory()

    const branchLinkPromise = plusLinkMaker('usmle');

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>

                    <div className="HomeBackground">
                        <div class="EmbeddedVideo">
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/DC32X-jJtQo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <h1 style={{ textAlign: 'center' }} className="mt-16 mb-16 reveal-from-bottom">
                            Study for the USMLE Step 1 with your <span className="text-color-primary">voice</span>
                        </h1>
                        <div className="Centered">
                            <Button onClick={() => {
                                window.gtag('event', 'get_started_med')
                                if (isMobile) {
                                    branchLinkPromise.then(({url}) => {
                                        window.gtag('event', 'enter_branch');
                                        window.location.href = url
                                    })
                                } else {
                                    props.history.push(`gomobile?subject=usmle&from=from_usmle`)
                                }
                            }} tag="a" style={{backgroundColor: "#61cbf2", border: "solid 1px #61cbf2"}} wideMobile>
                                Get Started
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default withRouter(Med);
