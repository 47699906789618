import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';

import { ReactComponent as Driving } from '../assets/images/driving.svg';
import { ReactComponent as Exercising } from '../assets/images/exercising.svg';
import { ReactComponent as Chores } from '../assets/images/chores.svg';
import { ReactComponent as Cook } from '../assets/images/cook.svg';
import { ReactComponent as LateWork } from '../assets/images/latework.svg';
import { ReactComponent as Relax } from '../assets/images/relax.svg';

import '../design/Pitch.css';

import {
    linkMaker
} from '../serverCalls/api2';

import NumericInput from 'react-numeric-input';
import queryString from 'query-string';

import {
    Accordion,
    Button,
    Col,
    Container,
    Card,
    Table
} from 'react-bootstrap';

const PageState = {
    start: 'start',
    schedule: 'schedule',
    scheduleNoHours: 'nohours',
    results: 'results'
};

class Pitch extends Component {

    constructor(props) {
        super(props);

        this.values = queryString.parse(this.props.location.search);

        this.state = {
            page: PageState.start,
            driving: 0,
            exercising: 0,
            chores: 0,
            cooking: 0,
            shareLink: undefined
        }
    }

    componentDidMount = () => {
        console.log('pitch_enter');
        let currentComponent = this;
        if (this.values.id) { // If the redirect is from set 
            linkMaker(this.values.id).then(({ url }) => {
                currentComponent.setState({ shareLink: url })
            });
        } else if (this.values.anki) { // If the redirect is from anki
            // Do something?
        }
    }

    WeeklyStudyTime = (props) => {
        return (
            <div className="DynamicVerticalContainer">
                <Container>
                    <div className="Title">
                        How do you spend your time every week?
                    </div>
                    <Accordion className="PitchAccordion" defaultActiveKey="drive">

                        <Card>
                            <Accordion.Toggle className="AccordionHeader" as={Card.Header} eventKey="drive">
                                <div>
                                    Driving:
                                </div>
                                <div>
                                    {this.state.driving} Hours
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="drive">
                                <Card.Body>
                                    <Driving className="PitchImage" />
                                    <NumericInput
                                        mobile={true}
                                        min={0}
                                        max={168}
                                        value={this.state.driving}
                                        onChange={(value) => this.setState({ driving: value })}
                                        format={(num) => {
                                            return num + ' Hours';
                                        }}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle className="AccordionHeader" as={Card.Header} eventKey="exercise">
                                <div>
                                    Exercising:
                                </div>
                                <div>
                                    {this.state.exercising} Hours
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="exercise">
                                <Card.Body>
                                    <Exercising className="PitchImage" />
                                    <NumericInput
                                        mobile={true}
                                        min={0}
                                        max={168}
                                        value={this.state.exercising}
                                        onChange={(value) => this.setState({ exercising: value })}
                                        format={(num) => {
                                            return num + ' Hours';
                                        }}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle className="AccordionHeader" as={Card.Header} eventKey="chores">
                                <div>
                                    Doing Chores:
                                </div>
                                <div>
                                    {this.state.chores} Hours
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="chores">
                                <Card.Body>
                                    <Chores className="PitchImage" />
                                    <NumericInput
                                        mobile={true}
                                        min={0}
                                        max={168}
                                        value={this.state.chores}
                                        onChange={(value) => this.setState({ chores: value })}
                                        format={(num) => {
                                            return num + ' Hours';
                                        }}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle className="AccordionHeader" as={Card.Header} eventKey="cook">
                                <div>
                                    Cooking:
                                </div>
                                <div>
                                    {this.state.cooking} Hours
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="cook">
                                <Card.Body>
                                    <Cook className="PitchImage" />
                                    <NumericInput
                                        mobile={true}
                                        min={0}
                                        max={168}
                                        value={this.state.cooking}
                                        onChange={(value) => this.setState({ cooking: value })}
                                        format={(num) => {
                                            return num + ' Hours';
                                        }}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                    <div className="ActionBar">
                        <Col xs={8} md={6} lg={3} >
                            <Button className="PitchButton" type="success" onClick={() => {
                                if (this.state.cooking + this.state.driving + this.state.exercising + this.state.chores === 0) {
                                    this.setState({
                                        page: PageState.scheduleNoHours
                                    })
                                } else {
																		console.log('pitch_submit_time, {cooking, driving, exercising, chores}')
																		window.gtag('event','pitch_submit_time',{
																			'cooking': this.state.cooking,
																			'chores': this.state.chores,
																			'exercising': this.state.exercising,
																			'driving': this.state.driving
																		})
                                    this.setState({
                                        page: PageState.results
                                    })
                                }
                            }}>
                                {
                                    (this.state.page === PageState.schedule) ?
                                        "Done"
                                        :
                                        "Really, 0 hours?"
                                }
                            </Button>
                        </Col>
                    </div>
                </Container>
            </div>
        )
    }

    Start = (props) => {
        return (
            <div className="DynamicVerticalContainer">
                <Container>
                    <LateWork className="PitchImage" />
                    <p>
                        Finding time to study can be a major challenge...
                    </p>
                    <div className="ActionBar">
                        <Col xs={8} md={6} lg={3} >
                            <Button className="PitchButton" type="success" onClick={() => {
																window.gtag('event','pitch_go_on')
                                this.setState({ page: PageState.schedule })
                            }}>
                                Go on
                            </Button>
                        </Col>
                    </div>
                </Container>
            </div>
        )
    }

    Results = (props) => {

        const hours = this.state.chores + this.state.exercising + this.state.cooking + this.state.driving;

        return (
            <div className="DynamicVerticalContainer">
                <Container>
                    <Relax className="PitchImage" />
                    <p>
                        By simply using MyVoiceCards, you could gain <b>{hours}</b> hours
                        of study time <b>every week!</b>
                    </p>
                    <div className="ActionBar">
                        <Col xs={8} md={6} lg={3} >
                            <Button className="PitchButton" type="success" onClick={() => {
                                window.gtag('event', 'pitch_enter_branch');

                                // If from anki
                                if (this.values.anki) {
                                    this.props.history.push('anki')
                                    return
                                }

                                // If from set
                                if (!isMobile) {
                                    this.props.history.push(`gomobile?id=${this.values.id}&from=from_pitch`)
                                } else {
                                    if (this.state.shareLink === undefined) {
                                        // If we weren't able to get the link
                                        this.props.history.push(`gomobile?id=${this.values.id}&from=from_pitch`)
                                    } else {
                                        window.location = this.state.shareLink;
                                    }
                                }
                            }}>
                                Sign me up!
                            </Button>
                        </Col>
                    </div>
                </Container>
            </div>
        )
    }

    render() {
        if (this.state.page === PageState.start) {
            return (
                <this.Start />
            )
        } else if (this.state.page === PageState.schedule || this.state.page === PageState.scheduleNoHours) {
            return (
                <this.WeeklyStudyTime />
            )
        } else if (this.state.page === PageState.results) {
            return (
                <this.Results />
            )
        } else {
            return (
                <></>
            )
        }
    }
}

export default withRouter(Pitch);