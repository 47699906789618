import React from 'react';
import EditDisplay from '../containers/subComponents/EditDisplay';
import { Auth } from 'aws-amplify';
import { createSet } from '../serverCalls/api2';

class Create extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <EditDisplay
          title="Create a New Study Set"
          {...this.props}
          onMount={(component) => {
            Auth.currentUserInfo().then(user => {
              if (user) {
                // User is authenticated
              } else {
                // User is unauthenticated
                component.setState({ authState: 'signUp' })
              }
            })
          }}
          onSave={(component) => {

            var tempCardList = [...component.state.cardList];
            let newCards = tempCardList.filter(card => {
              return card.question.length > 0 || card.answer.length > 0
            })
            createSet(component.state.title, newCards, component.state.questionLang.value, component.state.answerLang.value).then((response) => {
              component.setState({saving:false})
              if (response.success) {
                component.props.history.push(`/set?id=${response.setId}`)
              } else {
                console.log("Error connecting")
              }
            })
          }}
        />
      </>
    )
  }

}

export default Create;