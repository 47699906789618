import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Auth} from 'aws-amplify';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const LandingPage = () => {

  const history = useHistory();

  useEffect(()=>{
    Auth.currentUserInfo().then(user => {
      if (user) {
        history.push('/')
      }
    })
  },[])

  return (
    <>
      <Hero className="illustration-section-01" />
      {/*<FeaturesTiles />*/}
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/*<Testimonial topDivider />*/}
      <Cta split />
    </>
  );
}

export default LandingPage;