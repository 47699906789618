import React from 'react';
import { withRouter, useHistory } from 'react-router';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import '../design/Home.css';
import '../design/Anki.css';
import { getAnkiUpdate } from '../serverCalls/api2'

import { ReactComponent as AnkiSync } from '../design/AnkiSync.svg'

import {
	Button
} from 'react-bootstrap'

const Alexa = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {

	const outerClasses = classNames(
		'features-tiles section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner pt-0',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: 'Select a Study Set',
	};

	const history = useHistory()

	const ankiLinkPromise = getAnkiUpdate();

	// // Display the pitch screen but only ever once
	// var pitchShown = localStorage.getItem('pitchShown') || '';
	// console.log(pitchShown)
	// if (pitchShown != 'yes') {
	//     history.push(`pitch?anki=true`);
	//     localStorage.setItem('pitchShown', 'yes');
	// }

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>

					<div className="HomeBackground">
						<h1 style={{ textAlign: 'center' }} className="mt-16 mb-16">
							MyVoiceCards Alexa Skill
						</h1>
						<div className="container">
							<div class="row justify-content-md-center">
								<div class="col col-lg-6">
									<p style={{ textAlign: 'justify' }} className="mt-16 mb-16">
										Study your MyVoiceCards decks with Alexa!
										Just enable the MyVoiceCards Alexa skill,
										link your account, and use any Alexa enabled
										device to keep up to date with your flash cards.
									</p>
									<br />
									<div className="Centered">
										<Button onClick={() => {
											window.gtag('event', 'get_started_alexa')
											// TODO: Route to alexa
										}} tag="a" style={{ backgroundColor: "#61cbf2", border: "solid 1px #61cbf2" }} wideMobile>
											Get the Alexa Skill
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	)
}

export default withRouter(Alexa);