import React from 'react';
import { withRouter, useHistory } from 'react-router';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import '../design/Home.css';
import '../design/Med.css';
import { plusLinkMaker } from '../serverCalls/api2'

import {
	Button
} from 'react-bootstrap'

const Med = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {

	const outerClasses = classNames(
		'features-tiles section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner pt-0',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: 'Select a Study Set',
	};

	const history = useHistory()

	const branchLinkPromise = plusLinkMaker('usmle');

	return (
		<section
			{...props}
			className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>

					<div className="HomeBackground">
						Please subscribe
					</div>

				</div>
			</div>
		</section>
	)
}

export default withRouter(Med);
