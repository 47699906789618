export const langMap = {
  'en': { value: 'en', label: 'English (en)' },
  'es': { value: 'es', label: 'Spanish (es)' },
  'ru': { value: 'ru', label: 'Russian (ru)' },
  'ko': { value: 'ko', label: 'Korean (ko)' },
  'ja': { value: 'ja', label: 'Japanese (ja)' },

  'ar': { value: 'ar', label: 'Arabic (ar)' },
  'zh': { value: 'zh', label: 'Chinese (zh)' },
  'fr': { value: 'fr', label: 'French (fr)' },
  'de': { value: 'de', label: 'German (de)' },
  'it': { value: 'it', label: 'Italian (it)' },
  'pt': { value: 'pt', label: 'Portugese (pt)' },

  'da': { value: 'da', label: 'Danish (da)' },
  'nl': { value: 'nl', label: 'Dutch (nl)' },
  'hi': { value: 'hi', label: 'Hindi (hi)' },
  'is': { value: 'is', label: 'Icelandic (is)' },
  'nb': { value: 'nb', label: 'Norwegian (nb)' },
  'pl': { value: 'pl', label: 'Polish (pl)' },
  'ro': { value: 'ro', label: 'Romanian (ro)' },
  'sv': { value: 'sv', label: 'Swedish (sv)' },
  'tr': { value: 'tr', label: 'Turkish (tr)' },
  'cy': { value: 'cy', label: 'Welsh (cy)' },
}
