import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import '../design/Home.css';
import '../design/Terms.css';

const Terms = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Select a Study Set',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>

          <div className="HomeBackground">

            <div className="TermsContainer">
              <h1>Terms of Use and Privacy Policy</h1>

              <h2>Last updated: July 10th, 2019</h2>

              <h3>Thivos, LLC (“us”, “we”, “our”) operates https://myvoicecards.com (the “Sites”). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. Additionally, it informs you of the expectations of you while using the Site. By accessing the Site, you agree to the collection and use of information in accordance with the Privacy Policy, and to use the Site in compliance with the Terms of Use. All policies are governed by the laws of the State of Texas without regard to conflict of law provisions. These Terms of Use affect your legal rights and obligations, and should be read in full prior to use of the Site. Use of the Site serves as your acceptance of the Terms of Use and Privacy Policy below.</h3>

              <h2>TERMS OF USE</h2>

              <h2>Intellectual Property Ownership</h2>

              <h3>All information provided on the Site which is subject to copyright, trademark, or patent protection is the property of us, and will remain property of us. By using the site, you do not have the right to copy, reproduce, sell, use, or otherwise exercise the Intellectual Property rights in any way. Similarly, all information provided on the Site which is subject to copyright, trademark, or patent protection that is uploaded or provided by you will remain property of you. However, we reserve the right to track, or otherwise use the information, for functionality or marketing/advertising purposes. By uploading or providing information, you grant us an irrevocable, royalty free, non-exclusive, international license to use the information for the purposes stated above.</h3>

              <h2>Privacy Policy</h2>

              <h3>Our privacy policies and use and collection of personal information is outlined in our Privacy Policy, included on this page. The Privacy Policy is incorporated herein by reference. </h3>

              <h2>Disclaimer of Warranties and Liability</h2>

              <h3>THIS SITE IS PROVIDED “AS IS”. WE MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND WHATSOEVER RELATING TO THE SITE, LINKED SITES, OR OTHER CONTENT THAT MAY BE ACCESSIBLE DIRECTLY OR INDIRECTLY THROUGHOUT THE SITE. WE DISCLAIM TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL SUCH REPRESENTATIONS AND WARRANTIES. </h3>

              <h3>WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DISCLAIM TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (a) WARRANTIES OR MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, (b) WARRANTIES AGAINST INFRINGEMENT OF ANY THIRD-PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (c) WARRANTIES RELATING TO THE TRANSMISSION OR DELIVERY OF THE SITE, (d) WARRANTIES RELATING TO THE ACCURACY, RELIABILITY, CORRECTNESS, OR COMPLETENESS OF DATA MADE AVAILABLE ON THE SITE OR OTHERWISE BY US, AND (e) WARRANTIES OTHERWISE RELATING TO PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY US OR ANY THIRD PARTY. FURTHER THERE IS NO WARRANTY THAT THE SITE WILL MEET YOUR NEEDS OR REQUIREMENTS OR THE NEEDS OR REQUIREMENTS OF ANY OTHER PERSON.</h3>

              <h3>Additionally, no product or service purchased on the Site is guaranteed to be suitable for a particular purpose. It is the responsibility of the customer to review any and all products produced from the Site for use in any specific manner. We will not be liable for any damage to person or property stemming from misuse of a product produced from the Site. Additionally, we will not be liable for any mistakes made by the customer in the ordering or designing process. All contracts for product or services signed on the Site are subject to the terms and conditions specifically enumerated in the contract.</h3>

              <h2>Minors</h2>

              <h3>We do not specifically market to children under the age of 13 years old, or minors under the age of 18 years old. Additionally, the minimum age to engage with this Site, and order product from this Site is 18 years old. </h3>

              <h2>Indemnification</h2>

              <h3>User agrees to defend, indemnify, and hold harmless the Site and Thivos, along with its partners, owners, members, principals, employees, contractors, attorneys, agents, and representatives (individually, an “Indemnified Party” and collectively, the “Indemnified Parties”), for, against, and from (a) all third party claims arising out of or related to User’s use, actions, and statements made during the course of using the Site, including any losses, costs, damages, expenses (including, without limitation, attorneys’ fees) or other liabilities incurred by the Site and Thivos in responding to such third party claims, and (b) liabilities, claims (including, without limitation, claims and liabilities relating to remediation, bodily injury and property damage), causes of action, suits, judgments, damages, losses, costs, and expenses (including, without limitation, court costs, reasonable attorneys’ fees, and costs of work) of any nature (individually and collectively, “Losses”), to the extent arising out of, resulting from or incurred as a result of Contractor’s work, work product, actions, or statements made during the term of this Agreement</h3>

              <h2>User Comments, Posts, and Feedback</h2>

              <h3>Any information you transmit by text or post to the Site shall be considered non-confidential and non-proprietary, except for your Personal Information which is covered under this Privacy Policy. We do not claim ownership of any information or material you transmit, distribute, post, communicate, or send. </h3>

              <h3>Additionally, you agree not to transmit, distribute, post, communicate, or store information on this Site that:</h3>

              <h3>(a) is copyrighted, unless you are the copyright owner;</h3>
              <h3>(b) violates or infringes on any other intellectual property rights of others or the privacy or publicity rights of others;</h3>
              <h3>(c) reveals a trade secret, unless you own it;</h3>
              <h3>(d) is obscene, libelous, defamatory, threatening, harassing, abusive, malicious, hateful, sexually explicit, or embarrassing to any other person or entity, advocates, promotes, incites, instructs, informs, assists or otherwise encourages violence or other illegal activities, or involves fraud, stalking, or otherwise violating the legal rights of others, is harmful to minors, attempts to mislead others about your identity or the origin of a message or other communication, or impersonates or otherwise misrepresents your affiliation with any other person or entity, or is otherwise materially false, misleading, or inaccurate or otherwise objectionable, all as determined by RSL in its sole discretion;</h3>
              <h3>(e) violates any applicable law or regulation;</h3>
              <h3>(f) constitutes or contains junk mail, spam, advertisements or solicitations of a business, surveys, contests, chain letters, or pyramid schemes;</h3>
              <h3>(g) contains viruses, Trojan horses, worms, time bombs, or other computer programming routines, engines, or other software, data, or programs that are intended to or may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data information, or property of another including, but not limited to, the Site.</h3>

              <h2>Third Party Sites</h2>

              <h3>We do not include third party products or services on our websites. If you utilize a feature or connection on the site which directs you to, or incorporates, a third party you may be subject to their terms of use and privacy policy as well. Please ensure that you review those policies prior to utilizing any third party function.</h3>

              <h3>Our Site uses a third party payment processing service to process all payments made through the Site. We are not responsible for storing or collection of the payment information provided during the payment process. Additionally, you may be subject to the Square Terms and Conditions and Account Agreement by utilizing the payment feature on our Sites. </h3>

              <h2>International Use </h2>

              <h3>This site is intended for use in the United States, and United States territories. </h3>

              <h1>PRIVACY POLICY</h1>

              <h2>Information Collection and Use</h2>

              <h3>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, and phone number (“Personal Information”). This information will be used only to improve user experience on the Site, send out a periodic newsletter, or to respond to any communications we receive from you via the Site. We partner with third parties to provide services on the Site, specifically payment services. When the User engages with these services, the information necessary to provide those services will be given to the third party. These parties have not been authorized to use the information except for the purpose of providing the engaged service. Additionally, where permitted or required by law, we will provide personally identifying information to third parties, without your consent, to comply with court orders, subpoenas, or other legal or regulatory requirements. This information will not be sold to a third party. In the event this policy changes you will be notified via updates posted on the Terms and Conditions/ Privacy Policy section of the website.</h3>

              <h2>Log Data</h2>

              <h3>Like many site operators, we collect information that your browser sends whenever you visit our Site (”Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other similar statistics.</h3>

              <h3>In addition, we may use third party services such as Google Analytics that collect, monitor, and analyze the Log Data. </h3>

              <h2>Cookies</h2>

              <h3>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. </h3>

              <h3>Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</h3>

              <h2>Security</h2>

              <h3>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security against viruses, hackers, etc. Additionally, all payment information is currently processed through a third party payment service. We do not maintain any financial or payment information on our servers and are not responsible for the use, disclosure, or misuse of any such information.</h3>

              <h2>Opt Out</h2>

              <h3>If you wish for your information to be removed from our records, you may opt out by contacting us at contact@thivos.com. Please note that removal may take time to process, and will be reflected in our records as soon as possible. At the time you request to opt out we will inform you of the information which may be deleted, and that which may not. Some information cannot be removed due to technical limitations of the system/server. You may also request to review or make changes to the personally identifiable information as collected directly from our Site, please contact us as contact@thivos.com to do so.</h3>

              <h2>Changes to this Privacy Policy</h2>

              <h3>This Privacy Policy is effective as of July 10th, 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</h3>

              <h3>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Site after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</h3>

              <h2>Contact Us</h2>

              <h3>If you have any questions regarding our Privacy Policy, please contact us at contact@thivos.com. </h3>

            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default withRouter(Terms);
