import React, { useState } from 'react';
import classNames from 'classnames';
import SectionHeader from './../../components/sections/partials/SectionHeader';

import { langMap } from './../../langConfig';

import Select from 'react-select';

import {
  Button,
  Form,
  Modal,
  Spinner,
  Tab,
  Tabs
} from 'react-bootstrap';

import { AuthenticationModal } from './../subComponents/AuthenticationModal';

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import '../../design/Create.css';
import CenterSpinner from './CenterSpinner';

const quizletImportPlaceholder =
  `question1 \tanswer1\t
question2\tanswer2\t
question3\tanswer3\t
question4\tanswer4\t
question5\tanswer5`;

let FlashCardPreview = (props) => {
  return (
    <div className="FlashCardScroller">
      {
        (props.cards) ?
          props.cards.map((card) =>
            <div className="Question">
              <div className="QuestionHeader">
                {card.question}
              </div>
              <div className="FlashCardAnswer">
                {card.answer}
              </div>
            </div>
          )
          :
          <></>
      }
    </div>
  )
}

const CardDisplay = ({
  index,
  question,
  answer,
  onChangeQuestion,
  onChangeAnswer,
  onKeyDown
}) => {

  const [localQuestion, setQuestion] = useState(question);
  const [localAnswer, setAnswer] = useState(question);
  const [questionFocus, setQuestionFocus] = useState(false);
  const [answerFocus, setAnswerFocus] = useState(false);


  if (!questionFocus && localQuestion != question) {
    setQuestion(question);
  }
  if (!answerFocus && localAnswer != answer) {
    setAnswer(answer);
  }

  return (
    <>
      { /*
            <CardDisplay
                index={idx}
                question={card.question}
                answer={card.answer}
                onChangeQuestion={(e) => {
                    var tempCardList = [...this.state.cardList];
                    tempCardList[idx].question = e.target.value;
                    // Don't do this to make state update more efficient
                    // this.setState({ cardList: tempCardList })
                }}
                onChangeAnswer={(e) => {
                    var tempCardList = [...this.state.cardList];
                    tempCardList[idx].answer = e.target.value;
                    // Don't do this to make state update more efficient
                    // this.setState({ cardList: tempCardList })
                }}
                onPressEnter={(e) => {
                    var charCode = e.which || e.keyCode;

                    if (charCode == 9 && idx == this.state.cardList.length - 1) {
                        // Save the current card
                        var tempCardList = [...this.state.cardList];
                        // Add a new card to the end
                        tempCardList.push({ question: "", answer: "" })
                        // Load the new card
                        this.setState({
                            cardList: tempCardList
                        })
                    }
                }}
            />
            */ }
      <div className="Card">
        <TextareaAutosize
          id={`question-${index}`}
          class="CardTextArea"
          placeholder="Enter Question"
          value={localQuestion}
          onChange={(e) => {
            setQuestion(e.target.value);
            onChangeQuestion(e);
          }}
          onFocus={() => {
            setQuestionFocus(true);
          }}
          onBlur={() => {
            setQuestionFocus(false);
          }}
          rowsMin="3"
        />
        <div class="CardQADivider" />
        <TextareaAutosize
          id={`answer-${index}`}
          class="CardTextArea"
          placeholder="Enter Answer"
          value={localAnswer}
          onChange={(e) => {
            setAnswer(e.target.value);
            onChangeAnswer(e);
          }}
          onFocus={() => {
            setAnswerFocus(true);
          }}
          onBlur={() => {
            setAnswerFocus(false);
          }}
          onKeyDown={onKeyDown}
          rowsMin="3"
        />
      </div>
    </>

  )
}

const AudioButton = ({
  onClick,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  return (
    <ion-icon
      style={{
        cursor: 'pointer',
        color: 'blue',
        margin: '5px'
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      name={hover ? 'volume-high' : 'volume-high-outline'}
    />
  )
}

const DeleteButton = ({
  onClick,
  ...props
}) => {

  const [hover, setHover] = useState(false);

  const buttonStyle = {
    color: 'red',
    cursor: 'pointer',
    margin: '5px',
  };

  if (hover) {
    return (
      <ion-icon
        style={buttonStyle}
        onMouseLeave={() => {
          setHover(false)
        }}
        onClick={onClick}
        name="trash"
      />
    )
  } else {
    return (
      <ion-icon
        style={buttonStyle}
        onMouseEnter={() => {
          setHover(true)
        }}
        onClick={onClick}
        name="trash-outline"
      />
    )
  }
}

class EditDisplay extends React.Component {

  constructor({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    title,
    ...props
  }) {
    super(props)

    this.superProps = props

    this.outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    this.innerClasses = classNames(
      'features-tiles-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    this.tilesClasses = classNames(
      'tiles-wrap center-content',
      pushLeft && 'push-left'
    );

    this.sectionHeader = {
      title: title,
    };

    this.state = {
      authState: undefined,
      leftNav: false,
      rightNav: false,
      title: "",
      pending: false,
      idx: 0,
      cardList: [{
        question: "",
        answer: ""
      }]
    }

    this.importingAnki = false

    this.synth = window.speechSynthesis;

  }

  componentDidMount() {

    // Loading behavior is controlled by the parent component
    this.props.onMount(this)

  }

  loadQuizletStudySet = (text) => {

    // Extract 'deck' from the text
    let regexp = /(.*?)\t(.*?)\t/gs;
    let matches = [...text.matchAll(regexp)];

    // Convert the deck into a my-voice-cards study set
    var cards = matches.map(match => {
      return ({
        question: match[1],
        answer: match[2],
      });
    });
    cards = cards.map(card => {
      var tempCard = card;
      tempCard.answer = tempCard.answer.replace(/<[a-zA-Z0-9]*?>/g, "")
      tempCard.answer = tempCard.answer.replace(/<[a-zA-Z0-9]*? \/>/g, "")
      tempCard.answer = tempCard.answer.replace(/<\/[a-zA-Z0-9]*?>/g, "")
      tempCard.answer = tempCard.answer.replace(/&nbsp;/g, " ")
      tempCard.answer = tempCard.answer.replace(/&amp;/g, "&")
      tempCard.answer = tempCard.answer.replace(/--gt;/g, " > ")
      tempCard.question = tempCard.question.replace(/<[a-zA-Z0-9]*?>/g, "")
      tempCard.question = tempCard.question.replace(/<[a-zA-Z0-9]*? \/>/g, "")
      tempCard.question = tempCard.question.replace(/<\/[a-zA-Z0-9]*?>/g, "")
      tempCard.question = tempCard.question.replace(/&nbsp;/g, " ")
      tempCard.question = tempCard.question.replace(/&amp;/g, "&")
      tempCard.question = tempCard.question.replace(/--gt;/g, " > ")
      return tempCard
    })
    cards = cards.filter(card => {
      return (
        typeof card.question === 'string' &&
        card.question.length > 0 &&
        typeof card.answer === 'string' &&
        card.answer.length > 0
      );
    })
    this.setState({ quizletCards: cards });
  }

  importQuizletStudySet = async (cards) => {

    var tempCardList = [...this.state.cardList];
    tempCardList.splice(tempCardList.length, 0, ...cards);

    this.setState({
      showImportModal: false,
      importing: false,
      quizletCards: undefined,
      quizletText: "",
      cardList: tempCardList
    })

  }

  getAnkiFile = async (files) => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      // Great success! All the File APIs are supported.
    } else {
      this.importingAnki = false
      alert('Importing cannot be performed in this browser.');
    }
    if (files[0].name.match(/^.+\.txt$/)) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(files[0]);
      reader.onload = (e) => {
        this.setState({ ankiFile: files[0] });
      }
      await this.importAnki(files[0]);
    } else {
      this.setState({ ankFile: null });
      alert("Please review the instructions and upload a .txt file.");
    }
  }

  confirmAnkiImport = async () => {

    this.setState({ importing: true }, () => {
      setTimeout(() => {
        // Convert the cards into the proper format
        let cards = this.state.ankiCards.map(card => {
          return {
            question: card.question,
            answer: card.answer,
          }
        })

        var tempCardList = [...this.state.cardList];
        tempCardList.splice(tempCardList.length, 0, ...cards);

        this.setState({
          showImportModal: false,
          importing: false,
          ankiCards: undefined,
          ankiFile: undefined,
          cardList: tempCardList
        })
      }, 50);
    })


  }

  importAnki = async (fileToImport) => {

    // Avoiding multiple calls
    if (this.importingAnki === true) { return true }
    this.importingAnki = true;

    let reader = new FileReader();
    reader.onload = () => {

      let text = reader.result
      let regexp = /(.*?)\t(.*?)\n/gs;
      let matches = [...text.matchAll(regexp)];

      // Convert the cards into the proper format
      let cards = matches.map(card => {
        return {
          question: card[1],
          answer: card[2]
        }
      })
      this.setState({ ankiCards: cards })
    }
    reader.readAsText(fileToImport);

  }

  render() {
    return (
      <section
        {...this.props}
        className={this.outerClasses}
      >
        <div className="container">
          <div className={this.innerClasses}>
            <SectionHeader data={this.sectionHeader} className="center-content" />
            <div className={this.tilesClasses}>

              <AuthenticationModal
                modalState={this.state.authState}
                updateModalState={(s) => this.setState({ authState: s })}
                onAuthComplete={() => window.location.reload()}
                onAuthFail={() => this.props.history.push('/getstarted')}
              />

              <Modal
                show={this.state.showImportModal}
                centered="true"
                onHide={() => this.setState({ showImportModal: false })}
              >
                <Tabs activeKey={this.state.key} onSelect={(e) => this.setState({ key: e })}>
                  <Tab eventKey="quizlet" title="Quizlet">
                    <br />
                    <div className="QuizletImportSection">
                      <div className="QuizletImportHelpButtonRow">
                        <Button
                          onClick={() => {
                            window.gtag('event', 'click_import-instructions', { 'event_label': 'Quizlet' });
                            this.setState({ instructions: true });
                          }}
                          className="QuizletImportHelpButton"
                        >
                          Instructions
                        </Button>
                      </div>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        placeholder={quizletImportPlaceholder}
                        value={this.state.quizletText}
                        onChange={(e) => {
                          this.setState({ quizletText: e.target.value })
                          this.loadQuizletStudySet(e.target.value)
                        }}
                      />
                      <div className="QuizletImportSubmitRow">
                        {
                          (this.state.quizletCards) ?
                            <Button
                              onClick={() => {
                                if (!this.state.importing) {
                                  this.setState({ importing: true })
                                  window.gtag('event', 'import_cards', { 'event_label': 'Quizlet' })
                                  this.importQuizletStudySet(this.state.quizletCards)
                                }
                              }}
                              className="AnkiImportSubmitButton"
                            >
                              {
                                (this.state.importing)
                                  ? <Spinner animation="border" size="sm" />
                                  : "Import"
                              }
                            </Button>
                            :
                            <></>
                        }
                      </div>
                    </div>
                    <FlashCardPreview cards={this.state.quizletCards} />
                  </Tab>
                  <Tab eventKey="anki" title="Anki">
                    <br />
                    <div className="QuizletImportHelpButtonRow">
                      <Button
                        onClick={() => {
                          window.gtag('event', 'click_import-instructions', { 'event_label': 'Anki' });
                          this.setState({ ankiInstructions: true });
                        }}
                        className="QuizletImportHelpButton"
                      >
                        Instructions
                      </Button>
                    </div>
                    <input type="file" id="file" name="file" onChange={(e) => this.getAnkiFile(e.target.files)} />
                    {
                      (this.state.ankiCards) ?
                        <Button className="AnkiImportSubmitButton" onClick={() => {
                          window.gtag('event', 'import_cards', { 'event_label': 'Anki' })
                          this.confirmAnkiImport()
                        }}>
                          {
                            (this.state.importing)
                              ? <Spinner animation="border" size="sm" />
                              : "Import"
                          }
                        </Button>
                        :
                        <></>
                    }
                    <FlashCardPreview cards={this.state.ankiCards} />
                  </Tab>
                </Tabs>
              </Modal>

              <Modal
                show={this.state.instructions}
                centered="true"
                scrollable="true"
                onHide={() => this.setState({ instructions: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>How to import from Quizlet...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="QuizletImportInstructionsList">
                  <div>
                    1. Go to your quizlet study set, and under extra options, select export.
                  </div>
                  <div>
                    2. In the export menu, enter "\t" in the custom field under "Between term and definition", and enter "\t" in the custom field under "Between rows"
                  </div>
                  <div>
                    3. Press "copy text", paste that into MyVoiceCards in the import field, then press import.
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.ankiInstructions}
                centered="true"
                scrollable="true"
                onHide={() => this.setState({ ankiInstructions: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>How to import from Anki...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="QuizletImportInstructionsList">
                  <div>
                    <b>In Anki...</b>
                  </div>
                  <div>
                                        1. Select File > Export.
                  </div>
                  <div>
                    2. Select "Cards in Plain Text (.txt)" as the export format.
                  </div>
                  <div>
                    3. Include the desired decks.
                  </div>
                  <div>
                    4. Uncheck the "Include HTML and media references" box.
                  </div>
                  <div>
                    5. Export to your desired filename.
                  </div>
                  <div><br /></div>
                  <div>
                    <b>In MyVoiceCards...</b>
                  </div>
                  <div>
                    6. Press browse and select the file.
                  </div>
                  <div>
                    7. Import!
                  </div>
                </Modal.Body>
              </Modal>

              <div className="CreateCardFrame">
                {this.state.pending === true ? <CenterSpinner /> : <></>}
                <div className="CreateCardHeader">
                  <Form.Group className="FormGroup">
                    <Form.Label>Title</Form.Label>
                    <Form.Control componentClass="textarea" value={this.state.title} onChange={e => this.setState({ title: e.target.value })} placeholder="Enter Title Here" />
                  </Form.Group>
                  <Button
                    className="FormButton"
                    onClick={() => {
                      window.gtag('event', 'start_import');
                      this.setState({ showImportModal: true })
                    }}
                  >
                    Import
                  </Button>
                  <Button
                    className="FormButton"
                    onClick={() => {
                      this.setState({ saving: true }, () => {
                        setTimeout(() => {
                          window.gtag('event', 'save_set');
                          this.props.onSave(this)
                        }, 50)
                      })
                    }}
                  >
                    {this.state.saving ? <Spinner size="sm" animation="border" /> : "Save"}
                  </Button>
                </div>
                <div className="CreateCardLanguageBar">
                  <Select
                    className="LanguageDropdown"
                    options={Object.keys(langMap).map(key => {
                      return langMap[key]
                    })}
                    placeholder="Pick a question language..."
                    value={this.state.questionLang}
                    onChange={(lang) => {
                      window.gtag('event', 'set_language');
                      this.setState({ questionLang: lang })
                    }}
                  />
                  <Select
                    className="LanguageDropdown"
                    options={Object.keys(langMap).map(key => {
                      return langMap[key]
                    })}
                    placeholder="Pick an answer language..."
                    value={this.state.answerLang}
                    onChange={(lang) => {
                      window.gtag('event', 'set_language');
                      this.setState({ answerLang: lang })
                    }}
                  />
                </div>

                <div className="CreateCardBody">
                  <div className="CreateCardBodyCenter">
                    <div className="CardActionBar">
                      <span>Card #{this.state.idx + 1}/{this.state.cardList.length}</span>
                      <div style={{ flex: 1 }}>
                        <Button onClick={() => {
                          this.setState({
                            idx: Math.max(this.state.idx - 1, 0)
                          })
                        }}>
                          Previous
                        </Button>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Button onClick={() => {
                          this.setState({
                            idx: Math.min(this.state.idx + 1, this.state.cardList.length - 1)
                          })
                        }}>
                          Next
                        </Button>
                      </div>
                      <AudioButton onClick={() => {
                        console.log('audio')
                        console.log(this.synth)
                        var questionUtterance = new SpeechSynthesisUtterance(this.state.cardList[this.state.idx].question);
                        questionUtterance.lang = this.state.questionLang ? this.state.questionLang.value : 'en';
                        this.synth.speak(questionUtterance);
                        var answerUtterance = new SpeechSynthesisUtterance(this.state.cardList[this.state.idx].answer);
                        answerUtterance.lang = this.state.answerLang ? this.state.answerLang.value : 'en';
                        this.synth.speak(answerUtterance);
                      }} />
                      <DeleteButton onClick={() => {
                        // Delete the current card
                        var tempCardList = this.state.cardList;
                        tempCardList.splice(this.state.idx, 1);

                        if (this.state.idx < tempCardList.length) {
                          // If there are cards to the right, move to the right
                          this.setState({
                            cardList: tempCardList
                          })
                        } else if (this.state.idx > 0) {
                          // If there are no cards to the right, move to the left
                          this.setState({
                            cardList: tempCardList
                          })
                        } else {
                          window.gtag('event', 'add_card');
                          // If there are no cards remaining, create a new blank card and focus on it
                          // Add a new card to the end
                          var tempCardList = [...this.state.cardList];
                          tempCardList.push({ question: "", answer: "" })
                          // Load the new card
                          this.setState({
                            cardList: tempCardList
                          })
                        }
                      }} />
                    </div>
                    <div className="Card">
                      <TextareaAutosize
                        id={`question-${this.state.idx}`}
                        class="CardTextArea"
                        placeholder="Enter Question"
                        value={this.state.cardList[this.state.idx].question}
                        onChange={(e) => {
                          var tempCardList = [...this.state.cardList];
                          tempCardList[this.state.idx].question = e.target.value;
                          this.setState({ cardList: tempCardList })
                        }}
                        rowsMin="3"
                      />
                      <div class="CardQADivider" />
                      <TextareaAutosize
                        id={`answer-${this.state.idx}`}
                        class="CardTextArea"
                        placeholder="Enter Answer"
                        value={this.state.cardList[this.state.idx].answer}
                        onChange={(e) => {
                          var tempCardList = [...this.state.cardList];
                          tempCardList[this.state.idx].answer = e.target.value;
                          this.setState({ cardList: tempCardList })
                        }}
                        onKeyDown={(e) => {
                          var charCode = e.which || e.keyCode;

                          if (charCode == 9 && this.state.idx == this.state.cardList.length - 1) {
                            window.gtag('event', 'add_card');
                            // Save the current card
                            var tempCardList = [...this.state.cardList];
                            // Add a new card to the end
                            tempCardList.push({ question: "", answer: "" })
                            // Load the new card
                            this.setState({
                              cardList: tempCardList
                            })
                          }
                        }}
                        rowsMin="3"
                      />
                    </div>
                    <Button
                      className="FormButton"
                      style={{
                        marginTop: 40,
                        width: '100%'
                      }}
                      onClick={() => {
                        // Save the current card
                        var tempCardList = [...this.state.cardList];
                        // Add a new card to the end
                        tempCardList.push({ question: "", answer: "" })
                        // Load the new card
                        this.setState({
                          cardList: tempCardList,
                          idx: tempCardList.length - 1
                        })
                      }}
                    >
                      New Card
                    </Button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
    )
  }

}

export default EditDisplay;