import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

let CenterSpinner = (props) => {
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            '-moz-transform': 'translateX(-50%) translateY(-50%)',
            '-webkit-transform': 'translateX(-50%) translateY(-50%)',
            transform: 'translateX(-50%) translateY(-50%)'
        }}>
            <Spinner animation="border" />
        </div>
    )
}

export default CenterSpinner;