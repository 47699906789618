import React from 'react';

const NoHeaderFooter = ({ children }) => (
  <>
    <main className="site-content">
      {children}
    </main>
  </>
);

export default NoHeaderFooter;  