import React from 'react';
import queryString from 'query-string';
import EditDisplay from '../containers/subComponents/EditDisplay';
import { startEdit, endEdit } from '../serverCalls/api2';

import { langMap } from './../langConfig';

const charValMap = {};
const charArr = [...'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'];
const maxUnit = charArr.length - 1;
charArr.forEach((val, idx) => {
  charValMap[val] = idx;
});

function incOrderIndex(oldOrderIndex) {
  var idx0 = charValMap[oldOrderIndex[0]];
  var idx1 = charValMap[oldOrderIndex[1]];
  var idx2 = charValMap[oldOrderIndex[2]];

  if (idx2 == maxUnit) {
    if (idx1 == maxUnit) {
      idx0 += 1;
      idx1 = 0;
      idx2 = 0;
    } else {
      idx1 += 1;
      idx2 = 0;
    }
  } else {
    idx2 += 1;
  }

  return charArr[idx0] + charArr[idx1] + charArr[idx2];
}

const deepCopy = (arr) => {
  let copy = [];
  arr.forEach(elem => {
    if (Array.isArray(elem)) {
      copy.push(deepCopy(elem))
    } else {
      if (typeof elem === 'object') {
        copy.push(deepCopyObject(elem))
      } else {
        copy.push(elem)
      }
    }
  })
  return copy;
}// Helper function to deal with Objects

const deepCopyObject = (obj) => {
  let tempObj = {};
  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      tempObj[key] = deepCopy(value);
    } else {
      if (typeof value === 'object') {
        tempObj[key] = deepCopyObject(value);
      } else {
        tempObj[key] = value
      }
    }
  }
  return tempObj;
}

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.values = queryString.parse(this.props.location.search);
  }

  render() {
    return (
      <>
        <EditDisplay
          title="Edit"
          {...this.props}
          onMount={(component) => {
            component.setState({
              pending: true
            })
            startEdit(this.values.id).then(response => {

              // Make copies of the arrays to prevent
              // them from having the same values when
              // one changes
              component.rawCardList = deepCopy(response.cards);
              component.setState({
                title: response.title,
                questionLang: langMap[response.questionLang],
                answerLang: langMap[response.answerLang],
                question: response.cards[0].question,
                answer: response.cards[0].answer,
                pending: false,
                cardList: deepCopy(response.cards)
              })
            })
          }}
          onSave={(component) => {

            var nextOrderIndex = incOrderIndex(component.rawCardList[component.rawCardList.length - 1].orderIndex)
            var tempCardList = deepCopy(component.state.cardList);

            // Make copies because they could have the same reference
            var tempNew = [...tempCardList.filter(card => {
              return card.question.length > 0 || card.answer.length > 0
            })]
            let tempOriginal = deepCopy(component.rawCardList);

            console.log('firstCardList', component.state.cardList[0])
            console.log('firstRawList', component.rawCardList[0])

            let newCards = [];
            var editedCards = [];
            var deletedCards = [];

            tempOriginal.forEach((item) => {
              if (tempNew.length === 0 || item.cardId !== tempNew[0].cardId) {
                // Item does not exist
                deletedCards.push({
                  cardId: item.cardId
                });
              } else {
                // Item may be updated
                if (item.question !== tempNew[0].question || item.answer !== tempNew[0].answer) {
                  editedCards.push(tempNew[0]);
                }
                tempNew.splice(0, 1);
              }
            })
            newCards = tempNew;

            // Add order indices
            for (var i = 0; i < newCards.length; i += 1) {
              newCards[i].orderIndex = nextOrderIndex;
              nextOrderIndex = incOrderIndex(nextOrderIndex);
            }

            endEdit(
              this.values.id,
              newCards,
              editedCards,
              deletedCards,
              component.state.title,
              component.state.questionLang.value,
              component.state.answerLang.value
            ).then(response => {
              component.props.history.push(`/set?id=${this.values.id}`);
            })
          }}
        />
      </>
    )
  }

}

export default Edit;