import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader'
import '../design/Home.css';
import { Spinner } from 'react-bootstrap';

import { getHomeData } from '../serverCalls/api2';

class Home extends React.Component {

    constructor({
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...props
    }) {
        super(props)

        Auth.currentUserInfo().then(user => {
            if (!user) {
                this.props.history.push('/landing');
            }
        })

        // I use this to print out users id tokens when I need
        // Auth.currentSession().then(res => {
        //     console.log(res)
        //     console.log(res.getIdToken().getJwtToken())
        // })

        this.props = props

        this.outerClasses = classNames(
            'features-tiles section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        this.innerClasses = classNames(
            'features-tiles-inner section-inner pt-0',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        this.tilesClasses = classNames(
            'tiles-wrap center-content',
            pushLeft && 'push-left'
        );

        this.sectionHeader = {
            title: 'Home',
        };

        this.state = {
            hoverCreate: false,
            hoverSearch: false,
            ownedSets: [],
            recentSets: [],
            dueSets: [],
            pending: true
        }

    }

    componentDidMount() {
        Auth.currentUserInfo().then(info => {
            this.setState({
                username: info ? info.username : ""
            })
        })
        getHomeData().then(({ sets, recent, due }) => {
            if (sets || recent || due) {
                this.setState({ 
                    ownedSets: sets || [],
                    recentSets: recent || [],
                    dueSets: due || [],
                    pending: false
                })
            }
        })
    }

    render() {
        return (
            <section
                {...this.props}
                className={this.outerClasses}
            >
                <div className="container">
                    <div className={this.innerClasses}>
                        <SectionHeader data={this.sectionHeader} className="center-content" />

                        <div
                            className="TitleBar"
                        >
                            <div
                                className="CreateButton"
                                onMouseOver={() => {
                                    this.setState({ hoverSearch: true })
                                }}
                                onMouseOut={()=>{
                                    this.setState({hoverSearch: false})
                                }}
                                onClick={() => {
                                    window.gtag('event','select_search');
                                    this.props.history.push('/search')
                                }}
                            >
                                {
                                    this.state.hoverSearch ?
                                        <ion-icon name="search"></ion-icon>
                                        :
                                        <ion-icon name="search-outline"></ion-icon>
                                }
                            </div>
                            <div
                                className="CreateButton"
                                onMouseOver={() => {
                                    this.setState({ hoverCreate: true })
                                }}
                                onMouseOut={() => {
                                    this.setState({ hoverCreate: false })
                                }}
                                onClick={() => {
                                    window.gtag('event', 'create_set');
                                    this.props.history.push('/create')
                                }}
                            >
                                {
                                    this.state.hoverCreate ?
                                        <ion-icon name="create"></ion-icon>
                                        :
                                        <ion-icon name="create-outline"></ion-icon>
                                }
                                <div className="CreateButtonText">
                                    Create set
                                </div>
                            </div>
                            <div style={{flex: 1, fontWeight: 'bold', fontSize: 24}}>
                                {this.state.username}
                            </div>
                        </div>

                        <div className="HomeSection">
                            <div className="HomeSectionHeader">
                                Sets Scheduled for Today
                            </div>
                            {
                                this.state.dueSets.length>0 ?
                                    this.state.dueSets.map(set => {
                                        return (
                                            <div className="SetButton" onClick={() => {
                                                window.gtag('event', 'select_set');
                                                this.props.history.push(`/set?id=${set.setId}`)
                                            }}>
                                                {`${set.title}`}
                                            </div>
                                        )
                                    })
                                    :
                                    this.state.pending ? <Spinner animation="border" size="sm" /> : "You have no sets currently on schedule"
                            }
                        </div>

                        <div className="HomeSection">
                            <div className="HomeSectionHeader">
                                Recently Studied
                            </div>
                            {
                                this.state.recentSets.length>0 ?
                                    this.state.recentSets.map(set => {
                                        return (
                                            <div className="SetButton" onClick={() => {                                                
                                                window.gtag('event', 'select_set');
                                                this.props.history.push(`/set?id=${set.setId}`)
                                            }}>
                                                {`${set.title}`}
                                            </div>
                                        )
                                    })
                                    :
                                    this.state.pending ? <Spinner animation="border" size="sm" /> : "You do not have any recently studied sets"
                            }
                        </div>

                        <div className="HomeSection">
                            <div className="HomeSectionHeader">
                                Your Study Sets
                            </div>
                            {
                                this.state.ownedSets.length>0 ?
                                    this.state.ownedSets.map(set => {
                                        return (
                                            <div className="SetButton" onClick={() => {
                                                window.gtag('event', 'select_set');
                                                this.props.history.push(`/set?id=${set.setId}`)
                                            }}>
                                                {`${set.title}`}
                                            </div>
                                        )
                                    })
                                    :
                                    this.state.pending ? <Spinner animation="border" size="sm" /> : "You have not created any study sets"
                            }
                        </div>

                    </div>
                </div>
            </section>
        )
    }

}

export default Home;