import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';

import '../design/Home.css';
import '../design/SearchHome.css';

import { querySetTitles } from '../serverCalls/api2';
import { Spinner } from "react-bootstrap";

const quizletImportPlaceholder =
`question1 \tanswer1\t
question2\tanswer2\t
question3\tanswer3\t
question4\tanswer4\t
question5\tanswer5`;

function GET(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onreadystatechange = function (e) {
        if (xhr.readyState == 4) {
            callback(xhr);
        }
    };
    xhr.send(null);
}

class SearchHome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            quizletImportText: "",
            searchError: false
        }
    }

    submitSearchForm = (e) => {
        querySetTitles(this.state.searchText).then(response => {
            this.setState({
                isSearching: false,
                searchError: false,
                decks: response.data.Items
            })
        }, err => {
            this.setState({
                isSearching: false,
                searchError: true
            })
            console.log(`Error: ${JSON.stringify(err)}`);
        });
    }

    renderSearchResults = (props) => {
        if (this.state.isSearching === true) {
            return (
                <div className="SpinnerColumn">
                    <div className="SpinnerRow">
                        <Spinner animation="border" />
                    </div>
                </div>
            )
        } else if (this.state.searchError === false) {
            if (this.state.decks == null) {
                return (
                    <div className="SearchResults">
                        Please search for a study set
                    </div>
                );
            }
            if (this.state.decks.length === 0) {
                return (
                    <div className="SearchResults">
                        There are no matching results
                    </div>
                );
            }
            if (this.state.decks.length > 0) {
                return (
                    this.state.decks.map(set =>
                        <Button
                            className="SearchResultsButton"
                            onClick={() => {
                                window.gtag('event', 'select_set');
                                this.props.history.push(`/set?id=${set.setId.S}`)
                            }}
                        >
                            {set.title.S} | {set.username.S}
                        </Button>
                    )
                );
            }
        } else { // Error Case
            return (
                <div className="SearchResults">
                    There was an error retrieving sets. Please try again.
                </div>
            )
        }
    }

    render() {

        const outerClasses = classNames(
            'hero section',
        );


        const innerClasses = classNames(
            'hero-inner section-inner',
            this.props.topDivider && 'has-top-divider',
            this.props.bottomDivider && 'has-bottom-divider'
        );

        return (
            <div>

                <section
                    className={outerClasses}
                >
                    <div className={innerClasses}>
                        {
                            (this.state.isImporting === true) ?
                                (
                                    <div className="SpinnerColumn">
                                        <div className="SpinnerRow">
                                            <Spinner animation="border" />
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <>
                                        <Form className="SearchBar" onSubmit={(e) => {
                                            e.preventDefault();
                                            if (this.state.searchText.search(/^\s*$/) === -1) {
                                                window.gtag('event', 'query_sets');
                                                this.setState({
                                                    isSearching: true,
                                                    searchError: false
                                                })
                                                this.submitSearchForm(e);
                                            }
                                        }}>
                                            <Form.Group className="SearchBarField">
                                                <Form.Control
                                                    value={this.state.searchText}
                                                    placeholder="Study set title"
                                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Form>
                                        <this.renderSearchResults />
                                    </>
                                )
                        }
                    </div>
                </section>
            </div>
        )
    }
}

export default SearchHome;