import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { withRouter } from 'react-router';
import queryString from 'query-string';
import '../design/StudyMenu.css';
import { getCardsPaged, getCardsPagedAuth } from '../serverCalls/api2';
import classNames from 'classnames';
import { Auth } from 'aws-amplify';
import MobileStoreButton from 'react-mobile-store-button';
import { isIOS } from 'react-device-detect';
import CenterSpinner from './subComponents/CenterSpinner';

import { langMap } from './../langConfig';

const CardState = {
    front: "Front",
    back: "Back",
    complete: "Complete",
    pending: "Pending"
}

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getVoices(questionLang, answerLang) {
    return new Promise(
        function (resolve, reject) {

            let synth = window.speechSynthesis;

            let handleVoices = () => {

                let questionLangs = synth.getVoices().filter((voice) => {
                    if (questionLang === undefined) {
                        questionLang = 'en';
                    }
                    let regexpQ = new RegExp(`${questionLang}-[A-Z]{2}`)
                    return regexpQ.test(voice.lang)
                });
                let answerLangs = synth.getVoices().filter((voice) => {
                    if (answerLang === undefined) {
                        answerLang = 'en';
                    }
                    let regexpA = new RegExp(`${answerLang}-[A-Z]{2}`)
                    return regexpA.test(voice.lang)
                })

                // TODO: Set to google as preferred if possible
                let prefQuestionVoice = questionLangs.length > 0 ? questionLangs[0] : undefined;
                let prefAnswerVoice = answerLangs.length > 0 ? answerLangs[0] : undefined;

                resolve({
                    questionVoice: prefQuestionVoice,
                    answerVoice: prefAnswerVoice
                })
            }

            synth.onvoiceschanged = () => {
                handleVoices();
            }

            if (synth.getVoices().length === 0) {
                // Chrome
                // Edge
                // It will be handled by onvoiceschanged
                // TODO: At some point add a timeout just to be safe
            } else {
                // Firefox
                handleVoices();
            }

        }
    )
}

class Listen extends React.Component {

    constructor({
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...props
    }) {
        super(props)

        this.superProps = props

        this.outerClasses = classNames(
            'features-tiles section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        this.innerClasses = classNames(
            'features-tiles-inner section-inner pt-0',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        this.tilesClasses = classNames(
            'tiles-wrap center-content',
            pushLeft && 'push-left'
        );

        this.sectionHeader = {
            title: 'Create a Study Set',
        };

        this.values = queryString.parse(this.props.location.search);

        this.cardsList = [];

        this.state = {
            question: "",
            answer: "",
            cardState: undefined,
            playing: true
        }

        this.actionCounter = 0

    }

    componentDidMount() {

        this.synth = window.speechSynthesis;
        if (isIOS) {
            this.setState({
                iosUnavailable: true
            })
        } else {
            this.fetchNextPage()
        }

    }

    componentWillUnmount() {
        this.actionCounter += 1
        this.synth.cancel()
    }

    speakPhrase = (phrase, voice) => {

        // Update the phrase
        var updatedPhrase = phrase.replace(/_+/g, "blank");

        return new Promise(resolve => {
            var utterance = new SpeechSynthesisUtterance(updatedPhrase);
            utterance.voice = voice;
            utterance.addEventListener('end', function (event) {
                resolve();
            });
            this.synth.speak(utterance);
        });
    }

    fetchNextPage = () => {
        this.setState({
            cardState: CardState.pending
        })
        Auth.currentUserInfo().then(user => {
            if (user) {
                getCardsPagedAuth(this.values.id, this.nextPage).then(response => {
                    this.cardsList = response.cards;
                    this.nextPage = response.nextPage;
                    this.questionLang = response.questionLang;
                    this.answerLang = response.answerLang;
                    this.setState({
                        questionLang: response.questionLang,
                        answerLang: response.answerLang
                    })
                    getVoices(this.questionLang, this.answerLang).then(({ questionVoice, answerVoice }) => {
                        this.questionVoice = questionVoice;
                        this.answerVoice = answerVoice;
                        if (typeof questionVoice === 'undefined' || typeof answerVoice === 'undefined') {
                            this.setState({
                                showMissingVoice: true
                            })
                        } else if (this.cardsList.length === 0) {
                            this.setState({
                                cardState: CardState.complete
                            })
                        } else {
                            this.getNextCard()
                        }
                    })
                })
            } else {
                getCardsPaged(this.values.id, this.nextPage).then(response => {
                    this.cardsList = response.cards;
                    this.nextPage = response.nextPage;
                    this.questionLang = response.questionLang;
                    this.answerLang = response.answerLang;
                    this.setState({
                        questionLang: response.questionLang,
                        answerLang: response.answerLang
                    })
                    getVoices(this.questionLang, this.answerLang).then(({ questionVoice, answerVoice }) => {
                        this.questionVoice = questionVoice;
                        this.answerVoice = answerVoice;
                        if (typeof questionVoice === 'undefined' || typeof answerVoice === 'undefined') {
                            this.setState({
                                showMissingVoice: true
                            })
                        } else if (this.cardsList.length === 0) {
                            this.setState({
                                cardState: CardState.complete
                            })
                        } else {
                            this.getNextCard()
                        }
                    })
                })
            }
        })
    }

    getNextCard = async () => {

        window.gtag('event', 'speak_passive-card');

        const currentAction = this.actionCounter.valueOf();
        // 1. Display and speak the front of the card
        if (this.actionCounter !== currentAction) { return } // Check if cancelled
        this.setState({
            question: this.cardsList[0].question,
            answer: this.cardsList[0].answer,
            cardState: CardState.front,
            playing: true
        })
        await this.speakPhrase(this.cardsList[0].question, this.questionVoice)
        // 2. Wait for a short amount of time
        if (this.actionCounter !== currentAction) { return } // Check if cancelled
        await timeout(5000);
        // 3. Display and speak the back of the card
        if (this.actionCounter !== currentAction) { return } // Check if cancelled
        this.setState({
            cardState: CardState.back
        })
        await this.speakPhrase(this.cardsList[0].answer, this.answerVoice)
        // 4. Wait for a short amount of time
        if (this.actionCounter !== currentAction) { return } // Check if cancelled
        await timeout(5000);
        // 5. Remove the first card and continue
        if (this.actionCounter !== currentAction) { return } // Check if cancelled
        this.cardsList.splice(0, 1);
        if (this.cardsList.length > 0) {
            this.getNextCard();
        } else {
            this.fetchNextPage();
        }
    }

    CardDisplay = () => {
        return (
            <>
                <Modal
                    centered="true"
                    scrollable="true"
                    show={this.state.iosUnavailable}
                    onHide={() => window.history.back()}
                >
                    <Modal.Header>
                        <Modal.Title>
                            Sorry
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{marginBottom: 15}}>
                            Study-by-listen is unavailable on web browsers for iOS. 
                            Please install the app, or try a different device.
                        </div>
                        <MobileStoreButton
                            store='ios'
                            url='https://apps.apple.com/us/app/id1521045993'
                            linkProps={{ title: "iOS Store Button" }}
                            height="50px"
                            class="ios-store-button"
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    centered="true"
                    scrollable="true"
                    show={this.state.showMissingVoice}
                    onHide={() => window.history.back()}
                >
                    <Modal.Header>
                        <Modal.Title>
                            Sorry
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            This set needs your browser to speak 
                            {
                                (typeof langMap[this.state.questionLang] !== 'undefined' && typeof langMap[this.state.answerLang] !== 'undefined') ?
                                    ` ${langMap[this.state.questionLang].label} and ${langMap[this.state.answerLang].label}`
                                    :
                                (typeof langMap[this.state.questionLang] !== 'undefined') ?
                                    ` ${langMap[this.state.questionLang].label}`
                                    :
                                (typeof langMap[this.state.answerLang] !== 'undefined') ?
                                    `${langMap[this.state.answerLang].label}`
                                    :
                                    " "
                            }.
                            Try again in a different browser, or in the mobile app.
                        </div>
                        <div style={{marginTop: 15}}>
                            <MobileStoreButton
                                store='ios'
                                url='https://apps.apple.com/us/app/id1521045993'
                                linkProps={{ title: "iOS Store Button" }}
                                height="50px"
                                class="ios-store-button"
                            />
                            <MobileStoreButton
                                store='android'
                                url='https://play.google.com/store/apps/details?id=com.myvoicecardsnative'
                                linkProps={{ title: "Google Play Store Button" }}
                            />
                        </div>
                   </Modal.Body>
                </Modal>
                <div className="CardDisplay">
                    {this.state.cardState===CardState.pending ? <CenterSpinner /> : <></>}
                    <div>
                        {(this.state.cardState === CardState.front) ? this.state.question : this.state.answer}
                    </div>
                </div>
                <div className="ButtonRow">
                    <Button
                        className="PlayPauseButton"
                        onClick={() => {
                            this.actionCounter += 1
                            if (this.state.playing) {
                                // Pause
                                this.synth.cancel()
                                this.setState({
                                    question: "",
                                    answer: "",
                                    playing: false
                                })
                            } else {
                                // Resume
                                this.getNextCard()
                            }
                        }}
                    >
                        {this.state.playing ? (this.state.cardState===CardState.pending ? "Pending" : "Pause") : "Play"}
                    </Button>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <section
                    {...this.props}
                    className={this.outerClasses}
                >
                    <div className="container">
                        <div className={this.innerClasses}>
                            <this.CardDisplay />
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default Listen;