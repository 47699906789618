import React, { useState } from 'react';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader'
import Button from '../components/elements/Button';
import { Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Amplify, {Auth} from 'aws-amplify';

const GetStarted = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const history = useHistory();

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Select a Study Set',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <Button onClick={()=>{
                    window.gtag('event', 'start_demo');
                    history.push('/set?id=c24a210c-4984-435d-96c0-74c0b0fd6da0');
                   }} tag="a" color="primary" wideMobile className="space-buttons reveal-from-bottom" data-reveal-delay="200">
                    Try the Demo
                  </Button >
                  <br />
                  <Button onClick={()=>{
                    window.gtag('event', 'create_set');
                    history.push('/create')
                  }} tag="a" color="primary" wideMobile className="space-buttons reveal-from-bottom" data-reveal-delay="600">
                    Create a Set
                  </Button>
                  <br />
                  <Button onClick={()=>{
                    window.gtag('event', 'select_search');
                    history.push('/search')
                  }} tag="a" color="primary" wideMobile className="space-buttons reveal-from-bottom" data-reveal-delay="800">
                    Search Existing Sets
                  </Button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default GetStarted;