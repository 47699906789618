import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MobileStoreButton from 'react-mobile-store-button';
import { isFirefox } from 'react-device-detect';
import {
	Button,
	Col,
	DropdownButton,
	FormControl,
	InputGroup,
} from 'react-bootstrap'
import '../design/Home.css';
import '../design/Terms.css';
import '../design/GoMobile.css';
import classNames from 'classnames';
import { ReactComponent as VoiceAssistant } from '../assets/images/undraw_voice_assistant_nrv7.svg';

import queryString from 'query-string';
import QRCode from 'react-qr-code';

import {
	linkMaker, plusLinkMaker
} from '../serverCalls/api2';
import DropdownItem from 'react-bootstrap/DropdownItem';

const emailMatchRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class GoMobile extends Component {

	constructor(props) {
		super(props);
		this.values = queryString.parse(this.props.location.search);
		this.state = {
			phone: "",
			url: "",
			dropdownTitle: "Open Set",
			has_qr: undefined
		}

	}

	componentDidMount() {

		const has_qr = ['yes', 'no', 'only'][Math.floor(Math.random() * 3)];

    window.gtag('event', `go_mobile_qr_${has_qr}`);

		console.log(has_qr)
		this.setState({
			has_qr
		})
		var currentComponent = this;
		if (this.values.id) {
			linkMaker(this.values.id, [`hasQr_${has_qr}`,this.values.from]).then(response => {
				currentComponent.setState({
					url: response.url
				})
			})
		} else if (this.values.subject) {
			plusLinkMaker(this.values.subject, [`hasQr_${has_qr}`,this.values.from]).then(response => {
				currentComponent.setState({
					url: response.url
				})
			})
		}

	}

	render() {
		const outerClasses = classNames(
			'features-tiles section',
			this.props.topOuterDivider && 'has-top-divider',
			this.props.bottomOuterDivider && 'has-bottom-divider',
			this.props.hasBgColor && 'has-bg-color',
			this.props.invertColor && 'invert-color',
			this.props.className
		);

		const innerClasses = classNames(
			'features-tiles-inner section-inner pt-0',
			this.props.topDivider && 'has-top-divider',
			this.props.bottomDivider && 'has-bottom-divider',
			'app-store-container'
		);

		const copyTextToClipoard = (text) => {
			var textArea = document.createElement("textarea");
			textArea.value = text;
			textArea.style.position = "fixed";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
			} catch (err) {
				console.error(`Copying failed: ${err}`)
			}
		}

		const handleSubmit = (e) => {
			e.preventDefault()
			window.branch.sendSMS(parseInt(this.state.phone), {

			})
			console.log(this.state.phone)
		}

		return (
			<section
				{...this.props}
				className={outerClasses}
			>
				<div class="container">
					<div class="row align-items-center">
						<Col xs={12} md={6} lg={6}>
							<VoiceAssistant />
						</Col>
						<Col xs={12} md={6} lg={6} >

							<br />
							<p>
								<b>You need the mobile app!</b>
							</p>
							{
								(this.state.has_qr === "yes" || this.state.has_qr === "no") ?
									<>
										<p>
											Open this link on your phone
										</p>

										<InputGroup class="">
											<FormControl
												placeholder="Pending..."
												value={this.state.url}
												readOnly
											/>
											<InputGroup.Append>
												<Button
													variant="outline-secondary"
													onClick={() => {
														if (isFirefox) {
															alert("Sending the link directly to your phone does not work in Firefox. Please open the link on your phone some other way.")
															return;
														}
														if (this.state.url.length > 0) {
															window.open(this.state.url, '_blank').focus();
														}
													}}
												>
													Send to Phone
												</Button>
											</InputGroup.Append>
										</InputGroup>
									</>
									:
									<></>
							}

							<br />

							{
								(this.state.has_qr === "yes" || this.state.has_qr === "only") ?
									<>
										<p>
											Scan the QR code
										</p>

										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
											<QRCode value={this.state.url} />
										</div>
									</>
									:
									<></>
							}

						</Col>
					</div>
				</div>
			</section>
		);
	}
}

export default withRouter(GoMobile);