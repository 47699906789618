import React, { useRef, useEffect } from 'react';
import { Switch, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

import Create from './containers/Create';
import GoMobile from './containers/GoMobile';
import Set from './containers/Set';
import Edit from './containers/Edit';
import Home from './containers/Home';
import SearchHome from './containers/SearchHome';
import Terms from './containers/Terms';
import GetStarted from './containers/GetStarted';
import Study from './containers/Study';
import Listen from './containers/Listen';
import Pitch from './containers/Pitch';
import Med from './containers/Med';
import Anki from './containers/Anki';
import Alexa from './containers/Alexa';
import Subscribe from './containers/Subscribe';

import ScrollReveal from './utils/ScrollReveal';
import AppRoute from './utils/AppRoute';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import NoHeaderFooter from './layouts/NoHeaderFooter';

import LandingPage from './views/LandingPage';

// AWS Sdk
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

/*
const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={()=>(
        <Switch>
          <AppRoute 
            exact path="/" 
            render={(props) => <Home />}
          />
          <AppRoute 
            path="/set" 
            render={(props) => <EditHome {...props} session={this.state.session} />}
          />
          <AppRoute
            path="/search"
            render={(props) => <SearchHome {...props} />}
          />
          <AppRoute
            path="/study"
            render={(props) => <StudyMenu {...props} />}
          />
          <AppRoute
            path="/terms"
            render={(props) => <Terms {...props} />}
          />
          <AppRoute
            path="/gomobile"
            render={(props) => <GoMobile {...props} />}
          />
          <Route
            path="/about"
            render={(props) => <AboutPage {...props} />}
          />
        </Switch>
      )}
    />
  );
}
*/
const App = () => {

  const childRef = useRef();
  let location = useLocation();

  window.gtag('event', 'conversion', {'send_to': 'AW-744263597/g08aCIyV_98BEK2f8uIC'})

  // Run these only once when the user enters the site
  useEffect(() => {
    window.gtag('event', 'enter_site');
  },[])

  // Run these every time the path changes
  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/landing" component={LandingPage} layout={LayoutDefault} />
          <AppRoute exact path="/getstarted" component={GetStarted} layout={LayoutDefault} />
          <AppRoute exact path="/set" component={Set} layout={LayoutDefault} />
          <AppRoute exact path="/search" component={SearchHome} layout={LayoutDefault} />
          <AppRoute exact path="/terms" component={Terms} layout={LayoutDefault} />
          <AppRoute exact path="/gomobile" component={GoMobile} layout={LayoutDefault} />
          <AppRoute exact path="/study" component={Study} layout={LayoutDefault} />
          <AppRoute exact path="/create" component={Create} layout={LayoutDefault} />
          <AppRoute exact path="/edit" component={Edit} layout={LayoutDefault} />
          <AppRoute exact path="/listen" component={Listen} layout={LayoutDefault} />
          <AppRoute exact path="/pitch" component={Pitch} layout={NoHeaderFooter} />
          <AppRoute exact path="/usmle" component={Med} layout={LayoutDefault} />
          <AppRoute exact path="/anki" component={Anki} layout={LayoutDefault} />
					<AppRoute exact path="/subscribe" component={Subscribe} layout={LayoutDefault} />
					<AppRoute exact path="/alexa" component={Alexa} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;
